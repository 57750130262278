import { selectCard, unselectCard } from '../utils/alerts';
let isEmergencyAlert = false;

const setupAlertMenu = (componentId = 'incident-alert-modal') => {
  const alertTemplateSelect = document.getElementById('alert_template_id');
  if (alertTemplateSelect) {
    alertTemplateSelect.addEventListener('change', (event) => {
      const alertTemplateName = document.getElementById('alert_template_name');
      alertTemplateName.value = event.target.options[event.target.selectedIndex].text;
      const templateId = event.target.value;
      const eventId = event.target.dataset.eventId; 
      fetchTemplateContent(templateId, eventId);
    });
  }

  const alertModal = document.getElementById(componentId);
  if (!alertModal) return;

  setupQuickOptions();
}

const setupAlertTemplateMenu = (componentId = 'alert-template-form-container') => {
  const formContainer = document.getElementById(componentId);
  if (!formContainer) return;

  setupQuickOptions();
}

// TODO: Reduce number of lines and complexity of this function.
const setupQuickOptions = () => {
  const likelyPersonnelContainer = document.getElementById('alert-likely-personnel-container');
  const allPersonnelContainer = document.getElementById('alert-all-personnel-container');
  const likelyPersonnelContainerIndicator = document.getElementById('alert-likely-personnel-indicator');
  const allPersonnelContainerIndicator = document.getElementById('alert-all-personnel-indicator');
  const standardPriorityContainer = document.getElementById('alert-standard-priority-container');
  const emergencyPriorityContainer = document.getElementById('alert-emergency-priority-container');
  const standardPriorityContainerIndicator = document.getElementById('alert-standard-priority-indicator');
  const emergencyPriorityContainerIndicator = document.getElementById('alert-emergency-priority-indicator');
  const alertIcon = document.getElementById('alert-icon-modal');
  const alertPriorityDescription = document.getElementById('alert-priority-description');
  const alertChannelsDescription = document.getElementById('alert-channels-description');
  const submitAlertButton = document.getElementById('submit-alert-button');

  likelyPersonnelContainer.addEventListener('click', () => {
    unselectCard(allPersonnelContainer, allPersonnelContainerIndicator);
    selectCard(likelyPersonnelContainer, likelyPersonnelContainerIndicator, isEmergencyAlert);
    enableContainerInputs(likelyPersonnelContainer);
  });
  allPersonnelContainer.addEventListener('click', () => {
    unselectCard(likelyPersonnelContainer, likelyPersonnelContainerIndicator);
    selectCard(allPersonnelContainer, allPersonnelContainerIndicator, isEmergencyAlert);
    disableContainerInputs(likelyPersonnelContainer);
  });

  // TODO: Let's use the cleaner approach of scripts/pages/disruptions/alerts.js
  // Let's DRY this up
  standardPriorityContainer.addEventListener('click', () => {
    isEmergencyAlert = false;
    unselectCard(emergencyPriorityContainer, emergencyPriorityContainerIndicator);
    selectCard(standardPriorityContainer, standardPriorityContainerIndicator, isEmergencyAlert);
    if (alertIcon) toggleAlertIconColor(alertIcon);
    if (alertPriorityDescription) toggleAlertPriorityDescription(alertPriorityDescription);
    if (alertChannelsDescription) toggleAlertChannelsDescription(alertChannelsDescription);
    toggleRecipientsCards([likelyPersonnelContainer, allPersonnelContainer]);
    toggleRecipientsIndicators([likelyPersonnelContainerIndicator, allPersonnelContainerIndicator]);
    if (submitAlertButton) toggleSubmitAlertButton(submitAlertButton);
    disableContainerInputs(emergencyPriorityContainer);
  });

  // TODO: Let's use the cleaner approach of scripts/pages/disruptions/alerts.js
  // Let's DRY this up
  emergencyPriorityContainer.addEventListener('click', () => {
    isEmergencyAlert = true;
    unselectCard(standardPriorityContainer, standardPriorityContainerIndicator);
    selectCard(emergencyPriorityContainer, emergencyPriorityContainerIndicator, isEmergencyAlert);
    if (alertIcon) toggleAlertIconColor(alertIcon);
    if (alertPriorityDescription) toggleAlertPriorityDescription(alertPriorityDescription);
    if (alertChannelsDescription) toggleAlertChannelsDescription(alertChannelsDescription);
    toggleRecipientsCards([likelyPersonnelContainer, allPersonnelContainer]);
    toggleRecipientsIndicators([likelyPersonnelContainerIndicator, allPersonnelContainerIndicator]);
    if (submitAlertButton) toggleSubmitAlertButton(submitAlertButton);
    disableContainerInputs(standardPriorityContainer);
  });
};

const disableContainerInputs = (container) => {
  const inputs = Array.from(container.querySelectorAll('input'));
  inputs.forEach((input) => input.setAttribute('disabled', 'disabled'));
};

// TODO: Validate if this method is required since we are working with radio buttons
const enableContainerInputs = (container) => {
  const inputs = Array.from(container.querySelectorAll('input'));
  inputs.forEach((input) => input.removeAttribute('disabled'));
};

const toggleAlertIconColor = (alertIcon) => {
  if (isEmergencyAlert) {
    alertIcon.classList.replace('text-gray-700', 'text-red-600');
  } else {
    alertIcon.classList.replace('text-red-600', 'text-gray-700');
  }
};

const toggleAlertPriorityDescription = (alertPriorityDescription) => {
  if (isEmergencyAlert) {
    alertPriorityDescription.innerHTML = 'Recipients receive the alert on all available communication channels.';
  } else {
    alertPriorityDescription.innerHTML = 'Recipients receive the alert on their preferred communication channel.';
  }
};

const toggleAlertChannelsDescription = (alertChannelsDescription) => {
  if (isEmergencyAlert) {
    alertChannelsDescription.innerHTML = 'This alert will immediately be sent to recipients via all available communication channels including: SMS, Email, Push Notification.';
  } else {
    alertChannelsDescription.innerHTML = 'This alert will immediately be sent to affected personnel via their preferred communication channel.';
  }
};

const toggleRecipientsCards = (recipientsCards) => {
  const colors = ['indigo-700', 'red-600']
  const targetColor = isEmergencyAlert ? 'indigo-700' : 'red-600'
  recipientsCards.forEach((card) => {
    if (card.classList.contains(`border-${targetColor}`)) {
      const newColor = colors.find(color => color != targetColor);
      card.classList.replace(`border-${targetColor}`, `border-${newColor}`);
    }
  });
};

const toggleRecipientsIndicators = (recipientsIndicators) => {
  const colors = ['indigo-700', 'red-600']
  const targetColor = isEmergencyAlert ? 'indigo-700' : 'red-600'
  recipientsIndicators.forEach((indicator) => {
    if (indicator.classList.contains(`text-${targetColor}`)) {
      const newColor = colors.find(color => color != targetColor);
      indicator.classList.replace(`text-${targetColor}`, `text-${newColor}`);
    }
  });
};

const toggleSubmitAlertButton = (button) => {
  if (isEmergencyAlert) {
    button.value = button.dataset.emergencyText;
    button.classList.replace('bg-indigo-600', 'bg-red-600');
    button.classList.add('bg-red-600');
    button.classList.replace('hover:bg-indigo-700', 'hover:bg-red-700');
  } else {
    button.value = button.dataset.notEmergencyText;
    button.classList.replace('bg-red-600', 'bg-indigo-600');
    button.classList.replace('hover:bg-red-700', 'hover:bg-indigo-700');
  }
};

const fetchTemplateContent = async (templateId, eventId) => {
  if (templateId) {
    const url = `/alerts/alert_template_content?id=${encodeURIComponent(templateId)}&event_id=${encodeURIComponent(eventId)}`;

    try {
      const response = await fetch(url);
      const data = await response.json();

      // Set alert title
      document.getElementById('alert_name').value = data.title;

      // Not sure why but the content markdown has two elements, a div and a textarea.
      // I'm updating both to be safe.
      const form = document.getElementById('alert-form');
      const markdowns = form.querySelectorAll('.markdown');
      markdowns.forEach((markdown) => {
        markdown.innerHTML = data.content;
        if (markdown.hasAttribute('data-placeholder')) {
          markdown.removeAttribute('data-placeholder');
        }
      });

      setRadioButtonState(data.emergency, 'alert_emergency_true', 'alert_emergency_false');
      updatePriorityRules(data.emergency);

      if (data.hasOwnProperty('all_personnel')) {
        setRadioButtonState(data.all_personnel, 'alert_all_personnel_true', 'alert_all_personnel_false');
        updateRecipientRules(data.all_personnel, data.emergency);
      }
    }
    catch (error) {
      console.error('Error fetching template content:', error);
    }
  } else {
    console.error('Template ID is required');
  }
};

const setRadioButtonState = (condition, trueElementId, falseElementId) => {
  document.getElementById(trueElementId).checked = condition;
  document.getElementById(falseElementId).checked = !condition;
};

const updatePriorityRules = (isEmergencyAlert) => {
  const standardAlertPriorityContainer = document.getElementById('alert-standard-priority-container');
  const standardAlertPriorityContainerIndicator = document.getElementById('alert-standard-priority-indicator');
  const emergencyAlertPriorityContainer = document.getElementById('alert-emergency-priority-container');
  const emergencyAlertPriorityContainerIndicator = document.getElementById('alert-emergency-priority-indicator');

  if (isEmergencyAlert) {
    selectCard(emergencyAlertPriorityContainer, emergencyAlertPriorityContainerIndicator, true);
    unselectCard(standardAlertPriorityContainer, standardAlertPriorityContainerIndicator);
  } else {
    selectCard(standardAlertPriorityContainer, standardAlertPriorityContainerIndicator, false);
    unselectCard(emergencyAlertPriorityContainer, emergencyAlertPriorityContainerIndicator);
  }
};

const updateRecipientRules = (allPersonnel, isEmergencyAlert) => {
  const likelyPersonnelContainer = document.getElementById('alert-likely-personnel-container');
  const likelyPersonnelContainerIndicator = document.getElementById('alert-likely-personnel-indicator');
  const allPersonnelContainer = document.getElementById('alert-all-personnel-container');
  const allPersonnelContainerIndicator = document.getElementById('alert-all-personnel-indicator');

  if (allPersonnel) {
    selectCard(allPersonnelContainer, allPersonnelContainerIndicator, isEmergencyAlert);
    unselectCard(likelyPersonnelContainer, likelyPersonnelContainerIndicator);
  } else {
    selectCard(likelyPersonnelContainer, likelyPersonnelContainerIndicator, isEmergencyAlert);
    unselectCard(allPersonnelContainer, allPersonnelContainerIndicator);
  }
};

export { setupAlertMenu, setupAlertTemplateMenu };
